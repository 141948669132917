import React from 'react';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { MediaContextProvider } from 'day8-react/build/new-components/MediaContext/MediaContext';
import '../styles/styles.scss';

interface CustomAppProps extends AppProps {
  pageProps: {
    dehydratedState: {}
  }
}
const isDev = process.env.NODE_ENV === 'development';
function MyApp({ Component, pageProps }: CustomAppProps) {
  const AnyComponent = Component as any;
  return (
    <MediaContextProvider disableDynamicMediaQueries={isDev}>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
      </Head>
      <AnyComponent {...pageProps} />
    </MediaContextProvider>
  );
}

export default MyApp;